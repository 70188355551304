@use './vars' as *;
@use './mixin' as *;
@use './helpers' as *;
@import '~bulma';
@import './react-phone-input';
@import './react-toastify';
@import './modal-helpers';
@import './react-datepicker';
@import './react-tabs';
@import '../assets/icon-fonts/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  line-height: 120%;
  outline: none;
}

iframe#launcher-frame {
  width: 84px;
  min-width: 84px !important;
}

html {
  overflow: hidden;
}

html,
body {
  background: $color-primary-background;
  color: $color-text;

  font: {
    family: $font-primary;
    size: $body-size;
  }
}

html.is-clipped,
html.is-clipped .navbar,
.is-clipped .modal {
  padding-right: 10px;
}

a:focus {
  text-decoration: underline;
}

h1 {
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-bold;
  }

  transition: $default-transition-med;
}

h2,
h3,
h4 {
  white-space: pre-wrap;
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-regular;
  }

  transition: $default-transition-med;
}

h1 {
  font-size: 48px;

  @include mobileScreen {
    font-size: 36px;
  }
}

h2 {
  font-size: 36px;

  @include mobileScreen {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;

  @include mobileScreen {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;

  @include mobileScreen {
    font-size: 18px;
  }
}

h5 {
  font-size: 14px;
  font-weight: 500;
  color: $color-black-500;
}

a,
textarea,
input,
select,
button {
  font-family: $font-primary;
  color: $color-text;
  font-size: 14px;
  line-height: 150%;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-placeholder;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-placeholder;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-placeholder;
  }
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Microsoft Edge */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

label {
  color: $color-header;
  margin-bottom: 4px;
  display: block;

  font: {
    size: 14px;
    weight: $font-weight-medium;
  }
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #49484870;
  border-radius: 8px;
}

.marquee-left {
  overflow: hidden;
  &__text {
    white-space: nowrap;
    animation: marquee-left 6s linear infinite alternate;

    &--hover {
      white-space: nowrap;
      &:hover {
        animation: marquee-left 6s linear infinite alternate;
      }
    }
  }
}

@keyframes marquee-left {
  from {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  to {
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
}

@media print {
  * {
    overflow: hidden !important;
  }

  table tr td,
  table tr th {
    page-break-inside: avoid;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3 !important;
    // orphans: 5;
    // widows: 5;
    page-break-inside: avoid;
    // margin: 4px 0 !important;
  }

  .print__header--text {
    text-align: center !important;
    margin: 4px 0 !important;
  }
  .print__header {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .print__no-margin {
    margin: 0 !important;
  }
  .print-layout-min-width {
    min-width: 700px !important;
  }
  .layout-container {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    padding: 8px 0 !important;
    height: auto !important;
    > header {
      display: none;
    }
    nav {
      display: none;
    }
    > div {
      padding: 0 2px !important;
      > div {
        padding: 0 !important;
        > div {
          padding: 0 !important;
          > div {
            padding: 0;
            border: none;
          }
        }
      }
    }
    table {
      border-collapse: collapse !important;
      border: 1px solid !important;
      td {
        border: 1px solid !important;
        padding: 10px 2px 6px 2px !important;
        font-size: 12px !important;
        vertical-align: top;
        > p {
          font-size: 12px !important;
          word-break: break-word;
        }
        a {
          font-size: 12px !important;
        }
      }
    }
  }

  .hidden-border-top-table {
    table {
      border: none !important;

      tr:first-child td {
        border-top: none !important;
      }
    }
  }

  .hidden-border-right-table {
    table {
      border: none !important;

      tr td:last-child {
        border-right: none !important;
      }
    }
  }

  .table_report_print {
    overflow: none !important;
  }

  .ruch-payroll-report {
    width: 100% !important;
  }

  .print-report-container {
    > div:first-child {
      display: none;
    }
  }
  .print__link {
    > p {
      color: #333333 !important;
    }
  }
  .hidden-print {
    display: none !important;
  }
  button {
    display: none !important;
  }
  .print__display {
    display: block !important;
    font-size: 13px !important;
  }

  .print__display__input {
    display: inline-block !important;
  }
  .print__display--textArea {
    display: block !important;
    border: 1px solid #000;
    width: 100%;
    height: fit-content;
    min-height: 76px;
    padding: 4px 8px;
    word-break: break-word;
  }
  .print_section-layout {
    padding: 12px !important;
  }
  .print_section-layout-no-padding {
    padding: 0 !important;
  }
  .print_section-layout-no-margin-top {
    margin-top: 1px !important;
  }
  .print_section-layout-border {
    border: 1px solid #747474 !important;
  }
  .print-section-layout-no-border-top {
    border-top: none !important;
  }
  .print-font-size-10 {
    font-size: 11px !important;
  }
  .print-margin-top-16 {
    margin-top: 16px !important;
  }
  @page {
    margin: 1cm 0.5cm;
  }
}

.MuiTypography-body2,
.MuiTableCell-head {
  font-size: 13px !important;
}

.MuiTableCell-root span button div {
  font-size: 13px !important;
}

.MuiTableSortLabel-root {
  height: 15px !important;
}

@media print {
  #launcher-frame {
    display: none;
  }
}

@supports (-moz-appearance: none) {
  @media print {
    .layout-container {
      table {
        border: 1.5px solid !important;
        td {
          border: 1.5px solid !important;
        }
      }
      .print_section-layout {
        padding: 12px 8px !important;
      }
    }
  }
}
