:root {
  --primary: #333333;
  --secondary: #0088cc;
  --danger: #db0012;
  --success: #2d934e;
  --white: #ffffff;
  --black: #000000;
  --light-black: #656263;
  --background: #f7f8fa;
  --gray: #8d8d8d;
  --gray-light: #9f9f9f;
  --link: #0088cc;
  --link-dark: #2a5cb1;
  --light-blue: #e5f3fa;
}

$primary: #333333;
$link: #0088cc;
$success: #2d934e;
$warning: #e87839;
$danger: #db0012;
$danger-light: #f5d5cd;

// Basic Colors
$color-white: #ffffff;
$color-black: var(--black);
$color-light-black: var(--light-black);
$color-green: #1da832;
$color-red: #db0012;
$color-warning: #ca9d00;

$color-black-500: #2f302f;
$color-black-400: #444141;
$color-black-300: #656263;
$color-black-200: #918f90;
$color-black-100: #bdbcbc;
$color-black-50: #e5e4e4;

$color-primary-900: #1f1f1f;
$color-primary-800: #1f1f1f;
$color-primary-700: #333333;
$color-primary-600: #525252;
$color-primary-500: #787878;
$color-primary-400: #999999;
$color-primary-300: #b9b9b9;
$color-primary-200: #d9d9d9;
$color-primary-100: #ececec;
$color-primary-75: #f6f6f6;
$color-primary-50: #f8f8f8;

$color-red-light: #ffeeee;
$color-primary-red-light: #eefaff;

// Style color
$color-link: var(--link);
$color-link-dark: var(--link-dark);
$color-text: $color-black-500;
$color-text-gray: var(--gray);
$color-text-inactive: var(--gray-light);
$color-tab-panel: var(--light-black);

$color-header: $color-black-500;
$color-border: #cccccc;
$color-placeholder: #999999;

$color-primary: var(--primary);
$color-primary-background: var(--background);
$color-secondary: var(--secondary);
$color-light-secondary: var(--light-blue);

$color-info: #0088cc;
$color-info-bg: #e1f6fd;
$color-success: var(--success);
$color-success-bg: #fee0e0;
$color-danger: var(--danger);
$color-danger-bg: #fee0e0;
$color-warning: #f4762f;
$color-warning-bg: #f9e8d9;
$color-success-tag: #ebf9eb;
$color-input-disabled: #eeeeee;
$color-info-today: #38b3e0;

$green-gradient-transparent: #22767c95;
$orange-gradient-transparent: #8a722b95;
$red-gradient-transparent: #c6000095;
$option-gradient-transparent: #29287020;

// Font
$font-primary: 'Arial';
$font-secondary: 'Open Sans';
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Size
$body-size: 14px;
$color-text-size: 14px;

// button
$button-small: 32px;
$button-normal: 40px;
$button-medium: 48px;

// input
$input-small: 32px;
$input-normal: 40px;
$input-medium: 48px;

$input-padding: 0 12px;
$input-border-radius: 2px;

// border
$default-border: 1px solid $color-border;
$default-border-primary: 1px solid $color-primary;
$default-space: 12px;
$default-transition: all 0.2s ease-in-out;
$default-transition-med: all 0.4s ease-in-out;
$default-linear-trans: all 0.2s linear;
$default-linear-trans-med: all 0.4s linear;
$default-border-radius: 2px;

$navbar-height: 4rem;
$footer-height: $navbar-height;

$sidebar-width: 288px;
$sidebar-mini-width: 80px;

// reponsive checkpoint
$widescreen: 1440px;
$desktop: 1280px;
$tablet: 960px;
$mobile: 600px;

$secondary-container-width: 1100px;
$form-width-medium: 944px;
$form-width-normal: 770px;
$form-width-mini: 580px;

$default-shadow: 0px 4px 20px rgba(224, 224, 224, 0.5);

$focus-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
$error-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(218, 92, 92, 0.6);

$sidebar-bg-color: #001529;
$icon-bg-color: #001529;
$submenu-bg-color: #000c17;
$submenu-bg-color-collapsed: #000c17;
